// Fonts

// Avenir
@font-face {
  font-family: 'Avenir';
  src: url('~/src/assets/fonts/Avenir-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Avenir';
  src: url('~/src/assets/fonts/Avenir-Light-Italic.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Avenir';
  src: url('~/src/assets/fonts/Avenir.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Avenir';
  src: url('~/src/assets/fonts/Avenir-Italic.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Avenir';
  src: url('~/src/assets/fonts/Avenir-Semibold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Avenir';
  src: url('~/src/assets/fonts/Avenir-Semibold-Italic.woff2') format('woff2');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'Avenir';
  src: url('~/src/assets/fonts/Avenir-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Avenir';
  src: url('~/src/assets/fonts/Avenir-Bold-Italic.woff2') format('woff2');
  font-weight: 700;
  font-style: italic;
}

// Avenir Next
@font-face {
  font-family: 'AvenirNext';
  src: url('~/src/assets/fonts/AvenirNext-Ultralight.woff2') format('woff2');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'AvenirNext';
  src: url('~/src/assets/fonts/AvenirNext-Ultralight-Italic.woff2')
    format('woff2');
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: 'AvenirNext';
  src: url('~/src/assets/fonts/AvenirNext.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'AvenirNext';
  src: url('~/src/assets/fonts/AvenirNext-Italic.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'AvenirNext';
  src: url('~/src/assets/fonts/AvenirNext-Semibold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'AvenirNext';
  src: url('~/src/assets/fonts/AvenirNext-Semibold-Italic.woff2')
    format('woff2');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'AvenirNext';
  src: url('~/src/assets/fonts/AvenirNext-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'AvenirNext';
  src: url('~/src/assets/fonts/AvenirNext-Bold-Italic.woff2') format('woff2');
  font-weight: 700;
  font-style: italic;
}
