.modalContent {
  width: 435px;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  padding: 36px;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  position: absolute;
  background-color: #fff;
  border-radius: 15px;
}

.age-input {
  background-color: #FFFFFF;
  border-color: #E0E0E0;
  border-radius: 7px;

  input {
    box-shadow: none !important;
    color: #333232;
    font-weight: 500;

    &:hover,
    &:focus-within {
      border-color: #9C9B9C;
    }
  }
}


@media (max-width: 768px) {
  .modalContent {
    width: 90%;

    p {
      width: 100%;
    }
  }
}